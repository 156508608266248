import React from "react";
import styles from "../../styles/header.module.sass";

const SubHeader = ({ text, center, bold }) => {
  return (
    <div
      className={styles.subHeader}
      style={{
        textAlign: center ? "center" : "",
        fontWeight: bold ? 900 : 100,
      }}
    >
      {text}
    </div>
  );
};

export default SubHeader;
