import React from "react";
import styles from "../../styles/copybtn.module.sass";

const CopyBtn = ({ text }) => {
  const copyOnClick = (e) => {
    alert("Скопировано");
    navigator.clipboard.writeText(text);
  };

  return (
    <div className={styles.copyBtn}>
      <p onClick={copyOnClick}>{text}</p>
      <div onClick={copyOnClick}>copy</div>
    </div>
  );
};

export default CopyBtn;
