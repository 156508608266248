// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".copybtn_copyBtn__11y18 {\n  background-color: #fb1d2d;\n  border-radius: 10px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n  cursor: pointer;\n}\n.copybtn_copyBtn__11y18 p {\n  text-align: center;\n  width: calc(100% - 80px);\n  font-weight: bold;\n  color: #ffffff;\n  margin-top: 2px;\n}\n.copybtn_copyBtn__11y18 div {\n  width: 60px;\n  padding-top: 0px;\n  padding-bottom: 3px;\n  border-radius: 5px;\n  border: 2px solid #ffffff;\n  text-align: center;\n  font-weight: bold;\n  color: #ffffff;\n}", "",{"version":3,"sources":["webpack://src/styles/copybtn.module.sass"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,mBAAA;EACA,iBAAA;EACA,oBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AACJ;AAAI;EACI,kBAAA;EACA,wBAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;AAER;AADI;EACI,WAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;EACA,iBAAA;EACA,cAAA;AAGR","sourcesContent":[".copyBtn\n    background-color: #fb1d2d\n    border-radius: 10px\n    padding-top: 10px\n    padding-bottom: 10px\n    display: flex\n    flex-direction: row\n    cursor: pointer\n    p\n        text-align: center\n        width: calc(100% - 80px)\n        font-weight: bold\n        color: #ffffff\n        margin-top: 2px\n    div\n        width: 60px\n        padding-top: 0px\n        padding-bottom: 3px\n        border-radius: 5px\n        border: 2px solid #ffffff\n        text-align: center\n        font-weight: bold\n        color: #ffffff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyBtn": "copybtn_copyBtn__11y18"
};
export default ___CSS_LOADER_EXPORT___;
